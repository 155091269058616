import Quote from '@/classes/Quotation';
import { getToken as getTokenFromStorage, getTokenDecoden } from '@/helpers/tokenauth.js';
import { getSessionStorage } from '@/helpers/storage.js';
import { formatCapitalize } from '@/helpers/filters.js';
import api from '@/api/api';
export default class VtQuote extends Quote{
    
    token_auth = "";
    client_id = this.person.client_id;
    user_id = this.user_id;
    rfc = this.person.rfc;
    name = this.person.name;
    middle_name = this.person.middle_name;
    paternal_name = this.person.paternal_name;
    maternal_name = this.person.maternal_name;
    birthday = this.formatDate(this.person.birthday);
    quote_information = {
        settings_business: {
            "max_days_ahead_to_quote": 0,
            "default_decease_coverage_min": 50000,
            "default_decease_coverage_max": 2500000,
            "mercado_abierto": getSessionStorage('user-information').organizations.includes("snte") ? "NO" : "SI",
            "minimum_salary": 0
        },
        settings_quoter:{
            "business": process.env.VUE_APP_FONDO,
            "id": "0",
            "rfc": this.person.rfc,
            "gender": formatCapitalize(this.person.gender),
            "weight": this.weight,
            "height": this.height,
            "fractional_savings": 0.00,
            "smoker": (this.is_smoker)? 'SI':'NO',
            "sum_assured": this.assured_amount,
            "payment_type": "Mensual",
            "disability_prime": this.coverages.includes('invalidez')? 1:0,
            "sum_assured_diba": 0,
            "funeral_expenses_dependents": "NO",
            "sum_assured_dependents": 0,
            "number_dependents": 0,
            "funeral_expenses_spouse": "NO",
            "spouse": {},
            "modifiers": this.diseases.concat(this.hobbies, this.sports, this.surgery)
        }
    }

    constructor(values) {
        super({
            id: values.id,
            client_id: values.client_id,
            user_id: values.user_id,
            rfc: values.rfc,
            curp: values.curp,
            name: values.name,
            middle_name: values.middle_name,
            paternal_name: values.paternal_name,
            maternal_name: values.maternal_name,
            email: values.email,
            telephone: values.telephone,
            gender: values.gender,
            age: values.age,
            birthday: values.birthday,
            cellphone: values.cellphone,
            is_smoker: values.is_smoker,
            is_additional_insured: values.is_additional_insured,
            assured_amount: values.assured_amount,
            alcohol: values.alcohol,
            narcotics: values.narcotics,
            coverages: values.coverages,
            diseases: values.diseases,
            occupations: values.occupations,
            sports: values.sports,
            hobbies: values.hobbies,
            activities: values.activities,
            surgery: values.surgery,
            weight: values.weight,
            height: values.height,
            city_of_birth: values.city_of_birth,
            state_name_birth: values.state_name_birth,
            relationship: values.relationship
        });
    }

    execute() {
        return this.createJson();
    }

    createJson(){
        const {  token_auth, client_id, user_id, rfc, name, middle_name, paternal_name, maternal_name, birthday, quote_information } = this;

        return {
            token_auth,
            client_id,
            user_id,
            rfc,
            name,
            middle_name,
            paternal_name,
            maternal_name,
            birthday,
            quote_information 
        }
    }

    async post(params){        
        const token = getTokenFromStorage('ccapi');
        params.token_auth = token;
        const url = `${process.env.VUE_APP_CCAPI}/${process.env.VUE_APP_BUSINESS_FONCABSA}/vt_quote/execute`;
        const result = await this.createQuote(token, params, url)
        return result
    }

    formatNumber(number) {
        var numberStr = number.toString();
        var result = numberStr.slice(0, 1) + "." + numberStr.slice(1);
        return parseFloat(result);
    }

    formatDate(date) {
        var regexFecha = /^\d{2}\/\d{2}\/\d{4}$/;
            
        if (regexFecha.test(date)) {
            return date;
        }
        
        var parts = date.split("-");
        
        var year = parts[0];
        var month = parts[1];
        var day = parts[2];
        
        var dateConvert = day + "/" + month + "/" + year;
        
        return dateConvert;
    }

    get_modifiers_of_occupation =  async () =>
    {
        const url = `${process.env.VUE_APP_CCAPI}/${process.env.VUE_APP_BUSINESS_FONCABSA}/vt_quote/get_modifiers_of_ocupation`;

        const header = this.getHeaders()

        const params = { 
            token_auth: sessionStorage.getItem("login")
        };

        return await  api.httpGet(url, { header }, params).catch(err => { return err });
    }

    get_modifiers_of_activity =  async () =>
    {
        const url = `${process.env.VUE_APP_CCAPI}/${process.env.VUE_APP_BUSINESS_FONCABSA}/vt_quote/get_modifiers_of_activity`;

        const header = this.getHeaders()

        const params = { 
            token_auth: sessionStorage.getItem("login")
        };

        return await  api.httpGet(url, { header }, params).catch(err => { return err });
    }

    getActivities = async () => 
    {
        try
        {
            const resp = await this.get_modifiers_of_activity();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    getOccupations = async () => 
    {
        try
        {
            const resp = await this.get_modifiers_of_occupation();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_activities_list = async () => {
        let result = await this.getActivities();
        if(result.status){
            return result.data.map(element => {
                return {value: element.name, text: element.name}
            });
        }
    }

    get_occupations_list = async () => {
        let result = await this.getOccupations();
        if(result.status){
            return result.data.map(element => {
                return {value: element.name, text: element.name}
            });
        }
    }

    getHeaders(){
        return {
            headers: {
                "Content-Type": "application/json",
                "token_auth": sessionStorage.getItem("login")
            }
        };
    }

    get_catalog_disease = async () =>
    {
        const url = `${process.env.VUE_APP_CCAPI}/${process.env.VUE_APP_BUSINESS_FONCABSA}/vt_quote/get_modifiers_of_disease`;

        const header = this.getHeaders()

        const params = { 
            token_auth: sessionStorage.getItem("login")
        };

        return await  api.httpGet(url, { header }, params).catch(err => { return err });

    }

    get_modifiers_of_surgeries = async () => {
        const url = `${process.env.VUE_APP_CCAPI}/${process.env.VUE_APP_BUSINESS_FONCABSA}/vt_quote/get_modifiers_of_surgery`;

        const header = this.getHeaders()

        const params = { 
            token_auth: sessionStorage.getItem("login")
        };

        return await  api.httpGet(url, { header }, params).catch(err => { return err });

    }

    get_catalog_surgeries = async () => {
        try
        {
            const resp = await this.get_modifiers_of_surgeries();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_catalog_surgeries_list = async () => {
        let array_operations = []
        let result = await this.get_catalog_surgeries();
        if(result.status){
            result.data.map(element => { array_operations.push({value: element.name, text: element.name}); });
        }

        return array_operations;
    }
    
    get_disease = async () => {
        try
        {
            const resp = await this.get_catalog_disease();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_catalog_activity = async () =>
    {
        const url = `${process.env.VUE_APP_CCAPI}/${process.env.VUE_APP_BUSINESS_FONCABSA}/vt_quote/get_modifiers_of_activity`;

        const header = this.getHeaders()

        const params = { 
            token_auth: sessionStorage.getItem("login")
        };

        return await  api.httpGet(url, { header }, params).catch(err => { return err });

    }


    get_hobbies_list = async () => {
        let result = await this.get_hobbies();
        if(result.status){
            return result.data.map(element => {
                return {value: element.name, text: element.name}
            });
        }
    }

    get_hobbies = async () => 
    {
        try
        {
            const resp = await this.get_catalog_activity();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }
    
    get_diseases_list = async () => {
        let array_operations = []
        let result = await this.get_disease();
        if(result.status){
            result.data.map(element => {
                if(element.name.split(" ")[0] != "CIRUGÍA")
                    array_operations.push({value: element.name, text: element.name});
            });

        }
        return array_operations;
    }

}