import Quote from '@/classes/Quotation';
import { getToken as getTokenFromStorage } from '@/helpers/tokenauth.js'; 
import api from '@/api/api';

export default class VsQuote extends Quote {
    
    constructor(values) {
        super({id:values.id,client_id:values.client_id, user_id:values.user_id, rfc:values.rfc, curp:values.curp, name:values.name, middle_name:values.middle_name, paternal_name:values.paternal_name, maternal_name:values.maternal_name, email:values.email, telephone:values.telephone, gender:values.gender, age:values.age, birthday: values.birthday, cellphone: values.cellphone,is_smoker: values.is_smoker, is_additional_insured: values.is_additional_insured,assured_amount: values.assured_amount, alcohol: values.alcohol, narcotics: values.narcotics,coverages: values.coverages,diseases: values.diseases,occupations: values.occupations,sports: values.sports, hobbies: values.hobbies,activities: values.activities,surgery: values.surgery,weight: values.weight,height: values.height});
    }

    execute() {
        return this.createJson();
    }

    createJson(){
        const {
            is_smoker,
            assured_amount,
            coverages,
            diseases,
            occupations,
            sports,
            hobbies,
            activities,
            surgery,
            weight,
            height,
            user_id,
        } = this;

        let age = this.person.age;
        let gender = this.person.gender;
        
        return {
            user_id,
            age,
            gender,
            is_smoker,
            assured_amount,
            coverages,
            diseases,
            occupations,
            sports,
            hobbies,
            activities,
            surgery,
            weight,
            height,
        }
    }

    async post(params){
        const token = getTokenFromStorage();
        const url = `${process.env.VUE_APP_GSAPI}/vs/quote`;

        params.height = params.height.toString().replaceAll(".", "");
        if(!params.coverages.includes('basic')) params.coverages.push('basic');
        params.gender = params.gender != 'femenino'? 'male' : 'female';
        
        const result = await this.createQuote(token, params, url)
        return result
    }

    get_modifiers_of_occupation =  async () => {
        const url = `${process.env.VUE_APP_GSAPI}/catalog_occupations/index`;

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Token ${getTokenFromStorage()}`
        };

        const params = {};

        return await api.httpGet(url, headers, params).catch(err => { return err });
    }

    getOccupations = async () => {
        try
        {
            const resp = await this.get_modifiers_of_occupation();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_occupations_list = async () => {
        let result = await this.getOccupations();
        if(result.status){
            return result.data.map(element => {
                return {value: element.name, text: element.name}
            });
        }
    }

    get_modifiers_of_surgeries = async () => {
        const url = `${process.env.VUE_APP_GSAPI}/catalog_surgeries/index`;

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Token ${getTokenFromStorage()}`
        };

        const params = {};

        return await api.httpGet(url, headers, params).catch(err => { return err });
    }

    get_catalog_surgeries = async () => {
        try
        {
            const resp = await this.get_modifiers_of_surgeries();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_catalog_surgeries_list = async () => {
        let array_operations = []
        let result = await this.get_catalog_surgeries();
        if(result.status){
            result.data.map(element => { array_operations.push({value: element.name, text: element.name}); });
        }

        return array_operations;
    }

    get_catalog_diseases = async () => {
        const url = `${process.env.VUE_APP_GSAPI}/catalog_surgeries/index`;
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Token ${getTokenFromStorage()}`
        };

        const params = {};

        return await api.httpGet(url, headers, params).catch(err => { return err });
    }

    get_disease = async () => {
        try
        {
            const resp = await this.get_catalog_disease();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_diseases_list = async () => {
        let array_operations = []
        let result = await this.get_disease();
        if(result.status){
            result.data.map(element => {
                if(element.name.split(" ")[0] != "CIRUGÍA")
                    array_operations.push({value: element.name, text: element.name});
            });

        }
        return array_operations;
    }

    getHeaders(type = 'ccapi'){
        if(type == 'ccapi')
        return {
            headers: {
                "Content-Type": "application/json",
                "token_auth": sessionStorage.getItem("login")
            }
        };

        return {
            "Content-Type": "application/json",
            "Authorization": `Token ${getTokenFromStorage()}`
        };
    }

    get_catalog_occupations = async () => {

        const url = `${process.env.VUE_APP_GSAPI}/catalog_occupations/index`;

        const header = this.getHeaders()

        const params = {};

        return await api.httpGet(url, header, params).catch(err => { return err });
    }

    get_occupations = async () => 
    {
        try
        {
            const resp = await this.get_catalog_occupations();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            console.log(err)
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_activities_list = async () => {
        let result = await this.get_occupations();
        if(result.status){
            return result.data.map(element => {
                return {value: element.name, text: element.name}
            });
        }
    }

    async get_catalog_hobbies() {

        const url = `${process.env.VUE_APP_GSAPI}/catalog_activities/index`;

        const header = this.getHeaders('gsapi')

        const params = {};

        return await api.httpGet(url, header, params).catch(err => { return err });
    }

    get_hobbies = async () => 
    {
        try
        {
            const resp = await this.get_catalog_hobbies();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            console.log(err)
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_hobbies_list = async () =>
    {
        let result = await this.get_hobbies();
        if(result.status){
            return result.data.map(element => {
                return {value: element.name, text: element.name}
            });
        } 
    }

    get_catalog_extraprime_diseases = async () => {

        const url = `${process.env.VUE_APP_GSAPI}/products/index_extraprime_diseases`;

        const header = this.getHeaders('gsapi')

        const params = {
            product_id: 1,
            catalog_coverage_name: 'basica'
        };

        return await api.httpGet(url, header, params).catch(err => { return err });
    }

    get_catalog_rejected_diseases = async () => {

        const url = `${process.env.VUE_APP_GSAPI}/products/index_rejected_disease`;

        const header = this.getHeaders('gsapi')

        const params = {
            product_id: 1,
            catalog_coverage_name: 'basica'
        };

        return await api.httpGet(url, header, params).catch(err => { return err });
    }

    get_rejected_diseases = async () => 
    {
        try
        {
            const resp = await this.get_catalog_rejected_diseases();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            console.log(err)
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_extraprime_diseases = async () => 
    {
        try
        {
            const resp = await this.get_catalog_extraprime_diseases();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            console.log(err)
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }

    get_rejected_diseases_list = async () => {

        let result = await this.get_rejected_diseases();
        if(result.status){
            return result.data.map(element => {
                return {value: element.name, text: element.name}
            });
        }
    }


    get_diseases_list = async () => {

        let result = await this.get_extraprime_diseases();
        if(result.status){
            return result.data.map(element => {
                return {value: element.name, text: element.name}
            });
        }
    }

    get_medical_operations_list = async () => {
        let array_operations = []
        let result = await this.get_disease();
        if(result.status){
            result.data.map(element => {
                if(element.name.split(" ")[0] =="CIRUGÍA")
                    array_operations.push({value: element.name, text: element.name});
            });

        }
        return array_operations;
    }

    get_disease = async () => {
        try
        {
            const resp = await this.get_catalog_disease();
            return { status: true, data: resp.data.response.data, message: resp.data.response.message }
        }
        catch(err)
        {
            let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
            return { status: false, data: [], message: error }
        }
    }
}