import VsQuote from '@/classes/VsQuote';
import VtQuote from '@/classes/VtQuote';

export default class DataQuotation {
    new(type, values){
        if(type == 'vida simple'){
            return new VsQuote(values);
        }else{
            return new VtQuote(values);
        }
    }
}